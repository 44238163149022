// router.js or similar
import {createRouter, createWebHistory} from 'vue-router';
import LoginModal from '../components/LoginModal.vue';
import MainChatView from "@/views/MainChatView.vue";
import PocVideoChatbot from "@/views/PocVideoChatbot.vue";

const routes = [
    {
        path: '/auth/:auth_token?',
        component: LoginModal,
        props: true
    },
    {
        path: '/',
        component: MainChatView,
        props: true
    },
    {
        path: '/poc-video-chatbot',
        component: PocVideoChatbot,
        props: true
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
