<template>
  <div class="chat-window" ref="chatContainer">
    <Toast/>
    <div v-for="message in messages" :key="message.message_id"
         :class="['message-bubble', message.message_type === 'user' ? 'user-message' : 'agent-message']"
         @click="handleSingleClick(message.message)"
         @dblclick="handleDoubleClick(message.message_id)">
      <div v-html="renderMultiline(message.message)"></div>
      <div class="timestamp" v-if="visibleTimestamps.includes(message.message_id)">
        {{ new Date(message.message_time).toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
import {useToast} from "primevue/usetoast";

export default {
  name: 'ChatWindow',
  watch: {
    messages() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        container.scrollTop = container.scrollHeight;
      });
    }
  },
  computed: {
    reversedMessages() {
      return [...this.messages].reverse();
    }
  },
  mounted() {
    this.toast = useToast(); // Initialize toast in mounted lifecycle hook
    this.toastVisible = false; // Initialize a flag to track toast visibility
  },
  props: {
    messages: Array,
    current_user_id: Number
  },
  data() {
    return {
      visibleTimestamps: [], // Store message IDs for which timestamps are visible
      toastVisible: false
    };
  },
  methods: {
    renderMultiline(text) {
      return text.replace(/\n/g, '<br>');
    },
    handleSingleClick(text) {
      this.copyToClipboard(text);
      if (!this.toastVisible) {
        this.showToastMessage();
      }
    },
    handleDoubleClick(message_id) {
      // Logic for double click event
      this.toggleTimestamp(message_id);
    },
    copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          console.log("Text copied to clipboard");
        }).catch(err => {
          console.error('Failed to copy text to clipboard', err);
        });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    toggleTimestamp(message_id) {
      const index = this.visibleTimestamps.indexOf(message_id);
      if (index > -1) {
        // If already visible, hide it
        this.visibleTimestamps.splice(index, 1);
      } else {
        // Otherwise, show it
        this.visibleTimestamps.push(message_id);
      }
    },
    showToastMessage() {
      const toastLife = 3000;
      this.toast.add({severity: 'success', summary: 'Text copied to clipboard', life: toastLife});
      this.toastVisible = true;
      setTimeout(() => {
        this.toastVisible = false;
      }, toastLife);
    }
  }
};
</script>

<style scoped>
.chat-window {
  background-color: var(--background-color);
  /* max-width: 60%; */
  width: 60%;
  position: fixed;
  bottom: 120px;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;
  padding: 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
}

/* Media Query for Tablet and Mobile */
@media (max-width: 768px) {

  /* Adjust the max-width as needed for tablet breakpoint */
  .chat-window {
    width: 90%;
    padding: 20px;
    /* Adjust padding for smaller screens */
  }
}

/* Additional adjustments for very small screens, like mobile phones */
@media (max-width: 480px) {
  .chat-window {
    width: 90%;
    padding: 10px;
  }
}


.message-bubble {
  font-size: var(--text-font-size);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: var(--text-color);
  max-width: 80%;
  word-wrap: break-word;
}

.user-message {
  background-color: var(--primary-color);
  color: var(--background-color);
  align-self: flex-end;
}

.agent-message {
  background-color: var(--secondary-color);
  color: var(--text-color);
  align-self: flex-start;
}

.timestamp {
  font-size: var(----metatext-font-size);
  color: var(--background-color);
  text-align: right;
  margin-top: 5px;
}</style>
