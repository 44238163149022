<template>
  <div class="splitter-container">
    <Splitter layout="horizontal">
      <SplitterPanel size="50">
        <div class="panel-content">
          <ChatWindow v-if="isAuthenticated" :messages="messages" :current_user_id="user_id"/>
          <PromptArea @sendMessage="handleSendMessage" @receiveMessage="handleReceivedMessage"/>
        </div>
      </SplitterPanel>
      <SplitterPanel size="50">
        <div class="panel-content">
          <VideoWithDesc videoId="dQw4w9WgXcQ" description="Check out this amazing video!"/>
        </div>
      </SplitterPanel>
    </Splitter>
  </div>
</template>

<script>
import ChatWindow from "@/components/ChatWindow.vue";
import PromptArea from "@/components/PromptArea.vue";
import axios from "axios";
import VideoWithDesc from "@/components/VideoWithDesc.vue";

export default {
  name: 'PocVideoChatbot',
  components: {
    VideoWithDesc,
    ChatWindow,
    PromptArea
  },
  data() {
    return {
      isAuthenticated: false,
      user_id: null,
      conversation_id: null,
      messages: [],
    };
  },
  created() {
    const jwt = localStorage.getItem('jwt');
    this.conversation_id = localStorage.getItem('conversation_id');

    if (jwt && this.isJwtValid(jwt)) {
      this.isAuthenticated = true;
      this.user_id = this.parseJwt(jwt).user_id;
      this.fetchMessages();
    }
  },
  methods: {
    async fetchMessages() {
      try {
        const jwt = localStorage.getItem('jwt');
        if (!jwt || !this.conversation_id) return;

        const config = {
          headers: {
            Authorization: `Bearer ${jwt}`,
            conversation_id: this.conversation_id,
          },
        };
        const response = await axios.get(this.$config.apiBaseUrl + "/conversation", config);
        this.messages = response.data.map((msg) => ({
          ...msg,
        }));
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    },
    handleSendMessage(newMessageText) {
      const newMessage = {
        message: newMessageText,
        conversation_id: this.conversation_id,
        message_type: "user",
        message_category: "prompt",
        user_id: this.user_id,
        message_time: new Date(),
      };
      this.messages.unshift(newMessage);
    },
    handleReceivedMessage(responseData) {
      const newMessage = {
        conversation_id: responseData.conversation_id,
        message: responseData.message,
        message_type: responseData.message_type,
        message_category: responseData.message_category,
        user_id: responseData.user_id,
        message_time: responseData.message_time,
      };
      this.messages.unshift(newMessage);
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
          atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
      );
      return JSON.parse(jsonPayload);
    },
    isJwtValid(token) {
      if (!token) return false;

      const payloadBase64 = token.split(".")[1];
      const decodedJson = atob(payloadBase64);
      const decoded = JSON.parse(decodedJson);

      const exp = decoded.exp;
      const now = Date.now().valueOf() / 1000;

      return now < exp;
    },
  },
};
</script>

<style scoped>
.splitter-container {
  width: 90%; /* Set the container width to 90% */
  margin: 0 auto; /* Center it horizontally */
  height: 100vh; /* Take up full viewport height */
}

.panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.p-splitter-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
