<template>
  <HeaderLogo></HeaderLogo>

  <!--  Dynamic Content-->
  <router-view></router-view>

  <FooterStatic></FooterStatic>
</template>
<script>

import HeaderLogo from "@/components/HeaderLogo.vue";
import FooterStatic from "@/components/FooterStatic.vue";

export default {
  name: 'App',
  components: {FooterStatic, HeaderLogo}
};
</script>
