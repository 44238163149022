import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router'; // Import the router
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import 'primeicons/primeicons.css';
import 'primevue/resources/themes/lara-dark-teal/theme.css';
import 'primevue/resources/primevue.min.css'; // Core styling
import './assets/global.css'; // Make sure this path is correct


fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    const app = createApp(App);
    app.config.globalProperties.$config = config; // Set global config
    console.log("CONFIG:", config)
    app.use(PrimeVue);
    app.use(ToastService);
    app.use(router); // Use the router here
    app.mount('#app');
  })
  .catch(error => {
    console.error('Failed to load config:', error);
  });