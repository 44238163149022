<template>
  <div class="video-container">
    <div class="video-wrapper">
      <iframe
          width="560"
          height="315"
          :src="videoUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
      ></iframe>
    </div>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "VideoWithDesc",
  props: {
    videoId: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "This is a static description for the video.",
    },
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
  },
};
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.video-wrapper {
  width: 100%;
  max-width: 800px; /* Set a max width for the video */
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio for a 16:9 video */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

.description {
  margin-top: 20px;
  font-size: 1.2em;
  color: var(--text-color); /* Customize text color */
}
</style>
